.layout {
  width: 100vw;
  min-height: 100vh;
  flex-direction: column;
  padding-top: calc((100vw * .14 / 2) + (100vh * .22 / 2));
  padding-bottom: 24px;
}


.content {
  flex: 1;
  display: flex;
  justify-content: center;
}