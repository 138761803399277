.activeLink {
  position: relative;
  &::after{
    width: 100%;
    content: "";
    left: 0;
    bottom: -6px;
    background-color: #624BF5;
    position: absolute;
    height: 2px;
  }
}
